import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '../../components/Button/Button';
import IconTextBox from '../../components/IconTextBox/IconTextBox';
import InputField from '../../components/InputField/InputField';
import data from '../../content/LoginPage';
import { useUserAuth } from "../../context/UserAuthContext";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loginbutton, setLoginbutton] = useState("Login");
    const navigate = useNavigate();
    const { logIn, spinner, user } = useUserAuth();
    useEffect(() => {
        if (user?.hasOwnProperty("uid")) {
            navigate("/home");
        }
    }, [user, navigate]);
    data.inputfields.email.value = email;
    data.inputfields.password.value = password;
    data.inputfields.submitbutton.label = loginbutton
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        try {
            setLoginbutton("Logging in..");
            await logIn(email, password);
            navigate("/home");
        } catch (err) {
            console.error(err)
            setError(err.message);
        }
    };
    if (spinner) {
        return (
            <React.Fragment>
                <div className="flex flex-col justify-center items-center h-screen w-full bg-primary opacity-7">
                    <span className="flex h-12 w-12">
                        <span className="animate-ping absolute inline-flex h-12 w-12 rounded-full bg-sky-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-12 w-12 bg-sky-300"></span>
                    </span>
                    <h1 className="pt-8 animate-bounce text-4xl text-white">Loading....</h1>
                </div>
            </React.Fragment>
        );

    }
    return (
        <React.Fragment>
            <div className="flex flex-col md:flex-row lg:flex-row">
                <div className="pl-12 logincontentbackground md:h-screen lg:h-screen w-full lg:w-2/6 md:w-1/2 flex flex-col justify-center items-start pt-8 md:pt-0 lg:pt-0">
                    <div className=" text-white">
                        <span className="text-2xl md:text-5xl lg:text-5xl font-semibold">Affliate</span>
                        <h1 className="text-5xl md:text-8xl lg:text-8xl font-bold logintextbackground">Sign in</h1>
                    </div>
                    <div className=" text-white pt-6 pb-8 pr-12 flex flex-wrap">
                        {data.icontextbox.map((item, key) => (
                            <IconTextBox key={key} icontextboxdata={item}></IconTextBox>
                        ))}
                    </div>
                </div>
                <div className="loginformbackground pt-6 h-96 md:h-screen lg:h-screen w-full lg:w-4/6 md:w-1/2 flex flex-col items-center justify-center">
                    <h1 className="text-red-400">{error}</h1>
                    <form onSubmit={handleSubmit} className="w-80 pl-2 pr-2">
                        <InputField inputfielddata={data.inputfields.email} onChange={(val) => setEmail(val)} />
                        <InputField inputfielddata={data.inputfields.password} onChange={(val) => setPassword(val)} />
                        <Button buttondata={data.inputfields.submitbutton} />
                    </form>
                    <a href='/signup' className='my-2'>
                        <button className='text-white underline underline-offset-4'>Don{`'`}t have an account? <span>Signup</span></button>
                    </a>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Login