import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ProductContextProvider } from "./context/ProductContext";
import Onboard from "./components/Onboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import { SiteDetailsContextProvider } from "./context/SiteContext";
import "react-toastify/dist/ReactToastify.css";
import { SidebarContextProvider } from "./context/SidebarContext";
import { EarningsContextProvider } from "./context/EarningsContext";
import { StatisticsContextProvider } from "./context/StatisticsContext";
import { AnalyticsContextProvider } from "./context/AnalyticsContext";
import { CampaignContextProvider } from "./context/CampaignContext";
import { ThemeContextProvider } from "./context/ThemeContext";
import { LogProvider } from "./context/LogContext";
import axios from "axios";
import '@mantine/core/styles.css';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common["x-authkey"] = process.env.REACT_APP_AUTH_KEY;
axios.defaults.headers.common["x-sitecode"] = process.env.REACT_APP_SITE_CODE;

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const buildAppWrapper = (app) => {

  // return <ChakraProvider>
  return <Router>
    <SiteDetailsContextProvider>
      <SidebarContextProvider>
        <ThemeContextProvider>
          <UserAuthContextProvider>
            <LogProvider>
              <ProductContextProvider>
                <StatisticsContextProvider>
                  <AnalyticsContextProvider>
                    <EarningsContextProvider>
                      <CampaignContextProvider>
                        {app}
                      </CampaignContextProvider>
                    </EarningsContextProvider>
                  </AnalyticsContextProvider>
                </StatisticsContextProvider>
              </ProductContextProvider>
            </LogProvider>
          </UserAuthContextProvider>
        </ThemeContextProvider>
      </SidebarContextProvider>
    </SiteDetailsContextProvider>
  </ Router>
}
// </ChakraProvider> 

root.render(
  <React.StrictMode>
    {buildAppWrapper(
      <>
        <App />
        <ToastContainer />
        <Onboard />
      </>
    )}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
