import React, { Suspense, lazy } from "react";
import NavbarTopDashboard from "../components/Navbar/NavbarTop";
const NavbarSideDashboard = lazy(() =>
  import("../components/Navbar/NavbarSide")
);

function MainLayout({ color, children }) {
  return (
    <div className={color}>
      <NavbarTopDashboard />
      <div className="flex mx-auto">
        <Suspense
          fallback={
            <div className="border-b w-4 h-4 m-auto rounded-full border-primary  animate-spin" />
          }
        >
          <NavbarSideDashboard />
        </Suspense>
        <div className="p-6 container mx-auto w-full md:w-4/5">{children}</div>
      </div>
    </div>
  );
}

export default MainLayout;
