import axios from "axios";
import { createContext, useContext, useState } from "react";
import { timeconversiongraph, dayconversiongraph } from "../helper/helper";

const StatisticsContext = createContext(undefined);
export function StatisticsContextProvider({ children }) {
  const [labels, setConversionLabels] = useState([]);
  const [conversions, setConversions] = useState([]);
  const [traffics, setTraffics] = useState([]);
  const [trafficlabels, setTrafficlabels] = useState([]);
  const [icons, setIcons] = useState({});

  function statisticDetails(params, graphtype = "date") {
   
    setTraffics([]);
    axios
      .get("/api/affliate/statistics", {
        params: {
          ...params,
          requestName: "GET STATISTICS",
        },
      })
      .then((res) => {
        let daygraphdata = {} as any;

        if (graphtype === "time") {
          daygraphdata = timeconversiongraph(res.data.data);
        } else {
          daygraphdata = dayconversiongraph(res.data.data);
        }

        setTrafficlabels(daygraphdata.trafficlabels);
        setTraffics(daygraphdata.traffics);
      })
      .catch((err) => {
        console.error(err);
      });
  }




  function statisticDetailsConversion(params, graphtype = "date") {
    setConversionLabels([]);
    setConversions([]);
    
    axios
      .get("/api/affliate/statistics", {
        params: {
          ...params,
          requestName: "GET STATISTICS",
        },
      })
      .then((res) => {
        let daygraphdata = {} as any;

        if (graphtype === "time") {
          daygraphdata = timeconversiongraph(res.data.data);
        } else {
          daygraphdata = dayconversiongraph(res.data.data);
        }

        setConversionLabels(daygraphdata.labels);
        setConversions(daygraphdata.conversion);
        setIcons(daygraphdata.icons);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  
  return (
    <StatisticsContext.Provider
      value={{
        labels,
        icons,
        conversions,
        traffics,
        trafficlabels,
        statisticDetails,statisticDetailsConversion
      }}
    >
      {children}
    </StatisticsContext.Provider>
  );
}

export function useStatisticContext() {
  return useContext(StatisticsContext);
}
