import React from "react";

function InputField(props) {
  const { ...inputfielddata } = props.inputfielddata;
  const { onChange } = props;
  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };

  return (
    <div className={inputfielddata["wrapcss"]}>
      {inputfielddata["label"] !== undefined ||
        inputfielddata["label"] !== "" ? (
        <span className={inputfielddata["labelcss"]}>
          {inputfielddata["label"]}
        </span>
      ) : (
        ""
      )}

      {inputfielddata["type"] === "textarea" ? (
        <textarea
          type={inputfielddata["type"]}
          name={inputfielddata["name"]}
          defaultValue={inputfielddata["value"]}
          onChange={handleChange}
          className={inputfielddata["inputfieldcss"]}
          placeholder={inputfielddata["placeholder"]}
          disabled={props.disabled}
        />
      ) : (
        <input
          type={inputfielddata["type"]}
          name={inputfielddata["name"]}
          defaultValue={inputfielddata.value}
          // defaultValue={props.forceValue ? null : inputfielddata["value"]}
          // value={props.forceValue ? inputfielddata["value"] : null}
          onChange={handleChange}
          className={inputfielddata["inputfieldcss"]}
          placeholder={inputfielddata["placeholder"]}
          disabled={props.disabled}
        />
      )}
    </div>
  );
}
export default InputField;
