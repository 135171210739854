import React, { Suspense, lazy, useEffect, useState } from "react";
import moment from "moment";
import "src/App.css";
import Button from "src/components/Button/Button";
import Selectbox from "src/components/Selectbox/Selectbox";
import MainLayout from "src/Layout/MainLayout";
import { useUserAuth } from "src/context/UserAuthContext";
import { useCampaignContext } from "src/context/CampaignContext";
import Spinner from "src/components/Spinner/Spinner";
import Error from "src/components/Status/Error";
import {
  campaignStatusMap,
  dataExist,
  formatPrice,
  getReferralLink,
  separateCharacters,
  validResponse,
} from "src/helper/utils";

import { useProductContext } from "src/context/ProductContext";
import { CSVLink } from "react-csv";
import SuspenseWrapper from "src/components/SuspenseWrapper";
import { useSiteContext } from "src/context/SiteContext";

const CampaignsTab = lazy(() =>
  import("src/components/CampaignsTab/CampaignsTab")
);
const CampaignTable = lazy(() => import("src/components/Table/CampaignTable"));
const ProductsFlag = lazy(() => import("src/components/Onboard/ProductsFlag"));
const DateFilter = lazy(() => import("src/components/DateFilter"));

const status_data = [
  {
    label: "Pending",
    value: 0,
  },
  {
    label: "Active",
    value: 1,
  },
  {
    label: "Stopped",
    value: 3,
  },
];

const buttonData1 = {
  css: "text-center px-4 py-2 text-lg bg-primary text-white shadow rounded-lg ",
  icon: "",
  icontype: "",
  iconcss: "",
  buttontype: "button",
  label: "Add new Campaign",
};

const formatData = (products, item) => {
  const product = products.data.find((_item) => _item._id == item.product_id);

  // const discount = product
  //   ? product.price * (1 + product.commission / 100)
  //   : "";

  const discount = product ? product.price * (product.commission / 100) : "";

  return {
    _id: item._id,
    id: separateCharacters(item._id),
    // product_id: separateCharacters(item.product_id),
    campaignName: item.name,
    commisionsPerSale: product ? `${product.commission}%` : "",
    createdDate: moment(item.created_at, "YYYY/MM/DD h:mm A").format(
      "DD/MM/YYYY"
    ),
    discountApplied: dataExist(discount) ? `$${formatPrice(discount)}` : "",
    status: item.status,
    referralLink: getReferralLink({
      ref_code: item.ref_code,
      promotion_url: item.promotion_url,
    }),
  };
};

const status_selectbox = {
  name: "campaign-status",
  wrapcss: "flex flex-col  items-end",
  placeholder: { label: "All", value: "" },
  inputfieldcss: "form-select rounded-xl w-64 px-4 py-2",
};

const selectbox = {
  name: "campaign-product",
  wrapcss: "flex flex-col  items-end",
  placeholder: { label: "All Campaigns", value: "" },
  inputfieldcss: "form-select rounded-xl w-64 px-4 py-2",
};

function Campaign() {
  const [loading, setLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [rows, setRows] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    product_id: "",
    status: "",
    start_date: null,
    end_date: null,
  });

  const handleDateChange = (date_filter) => {
    setTableFilter({
      ...tableFilter,
      start_date: date_filter.start,
      end_date: date_filter.end,
    });
  };

  const handleTableFilterChange = (name, value) => {
    setTableFilter({
      ...tableFilter,
      [name]: value,
    });
  };

  useEffect(() => {
    const filter = {};

    if (dataExist(tableFilter.product_id)) {
      filter.product_id = tableFilter.product_id;
    }

    if (dataExist(tableFilter.status)) {
      filter.status = tableFilter.status;
    }

    if (dataExist(tableFilter.start_date)) {
      filter.start_date = tableFilter.start_date;
    }

    if (dataExist(tableFilter.end_date)) {
      filter.end_date = tableFilter.end_date;
    }

    if (user.uid && site._id) {
      campaignDetails({ ...filter, user_id: user.uid, site_id: site._id });
    }
  }, [tableFilter]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Campaign Name",
        accessor: "campaignName",
      },
      {
        Header: "Commisions per sale",
        accessor: "commisionsPerSale",
      },
      {
        Header: "Date Created",
        accessor: "createdDate",
      },
      {
        Header: "Discount applied",
        accessor: "discountApplied",
      },
    ],
    []
  );

  const { user } = useUserAuth();
  const { site } = useSiteContext();
  const { campaigns, campaignDetails, deleteCampaign } = useCampaignContext();

  const { products } = useProductContext();

  const removeCampaign = (id) => {
    setLoading(true);
    setDeleteError(false);

    deleteCampaign({ id }).then((value) => {
      if (!validResponse(value)) {
        setDeleteError(true);
      }
      // else {
      // campaignDetails({ user_id: user.uid });
      // }

      setLoading(false);
    });
  };

  useEffect(() => {
    if (user.uid && site._id) {
      campaignDetails({ user_id: user.uid, site_id: site._id });
    }
  }, [user.uid, site._id]);

  useEffect(() => {
    if (validResponse(campaigns)) {
      if (tableFilter.product_id) {
        setRows(
          campaigns.data
            ?.filter((item) => item.product_id === tableFilter.product_id)
            .map((item) => formatData(products, item))
        );
      } else {
        setRows(campaigns.data?.map((item) => formatData(products, item)));
      }
    }
  }, [campaigns]);

  return (
    <MainLayout color="bg-gray-100">
      <Suspense fallback={<div>Loading...</div>}>
        <ProductsFlag />
      </Suspense>

      <h2 className="text-3xl">
        Your <span className="font-bold">Campaigns</span>
      </h2>
      <div className="flex flex-col bg-white shadow-lg w-full p-4 mt-4">
        <Suspense fallback={<div>Loading...</div>}>
          <CampaignsTab />
        </Suspense>
      </div>

      <div className="w-full border-t-4 border-gray-400 my-4 py-4 space-y-4">
        <div className="flex flex-wrap gap-4">
          <a href="/campaign/create">
            <Button buttondata={buttonData1} />
          </a>

          <CSVLink
            data={[
              [
                "CAMPAIGN NAME",
                "COMMISIONS PER SALE",
                "DATE CREATED",
                "DISCOUNT APPLIED",
                "STATUS",
                "REFERRAL LINK",
              ],
              ...rows
                .map((item) => ({
                  campaignName: item.campaignName,
                  commisionsPerSale: item.commisionsPerSale,
                  createdDate: item.createdDate,
                  discountApplied: item.discountApplied,
                  status: campaignStatusMap[item.status]?.text,
                  referralLink: item.referralLink,
                }))
                .map((item) => Object.values(item)),
            ]}
            filename="Campaigns.csv"
            className="text-center px-4 py-2 text-lg bg-black text-white shadow rounded-lg "
            target="_blank"
          >
            Export to CSV
          </CSVLink>
        </div>

        <div className="flex flex-wrap gap-4 justify-between items-center">
          <div className="flex flex-wrap gap-4 items-center">
            <Selectbox
              inputfielddata={{
                ...status_selectbox,
                value: tableFilter.status,
                data: status_data,
              }}
              selectedOption={tableFilter.status}
              onChange={(value) => handleTableFilterChange("status", value)}
            />

            <Selectbox
              inputfielddata={{
                ...selectbox,
                value: tableFilter.product_id,
                data:
                  products.data?.map((item) => ({
                    label: item.details,
                    value: item._id,
                  })) ?? [],
              }}
              selectedOption={tableFilter.product_id}
              onChange={(value) => handleTableFilterChange("product_id", value)}
            />
          </div>

          <SuspenseWrapper>
            <DateFilter
              onDateChange={handleDateChange}
              css="border border-black rounded-xl w-32 px-4 py-2"
            />
          </SuspenseWrapper>
        </div>

        <div className="flex flex-col bg-white shadow-lg w-full p-4 mt-4 overflow-auto">
          {campaigns.status == "init" && <Spinner />}
          {campaigns.status == "error" && <Error />}
          {campaigns.status == "success" && (
            <SuspenseWrapper>
              <CampaignTable
                columns={columns}
                data={rows}
                removeCampaign={removeCampaign}
                loading={loading}
                deleteError={deleteError}
              />
            </SuspenseWrapper>
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default Campaign;
