import axios from "axios";
import { UserProfile } from "../@types/auth";



export type UpdateProps = {
  payout_provider_id: string
  account_id: string
  provider_details: any
}

const usePayout = (userProfile: UserProfile, setUserProfile) => {


  async function addPayoutProvider(payload) {
    try {
      let { data: response } = await axios.post("/api/affliate/payout_accounts/", payload, {
        params: {
          requestName: 'PAYOUT PROVIDER ADDED: ' + JSON.stringify(payload)
        }
      });
      const { data } = response;
      const { payout_accounts } = data;
      let updatedUser = { ...userProfile, payout_accounts }
      localStorage.setItem('userdetails', JSON.stringify(updatedUser));
      return updatedUser;
    } catch (error) {
      console.error(error);
    }
  }

  async function updatePayoutProvider(toUpdate: UpdateProps) {
    const updateData = {
      user_id: userProfile._id,
      payout_account: toUpdate
    }
    try {
      let { data: response } = await axios.put("/api/affliate/payout_accounts/", updateData, {
        params: {
          requestName: 'UPDATE PAYOUT PROVIDER: ' + JSON.stringify(updateData)
        }
      });
      const { data } = response;
      const { payout_accounts } = data;
      let updatedUser = { ...userProfile, payout_accounts }
      localStorage.setItem('userdetails', JSON.stringify(updatedUser));
      setUserProfile(updatedUser)
      return updatedUser
    } catch (error) {
      console.error(error);
    }
  }


  const deletePayoutProvider = async (account_id: string) => {
    try {
      const user_id = JSON.parse(localStorage.getItem("userdetails"))?._id;
      let response = await axios.delete(`/api/affliate/payout_accounts?user_id=${user_id}&account_id=${account_id}`, {
        params: { requestName: "DELETE PAYOUT PROVIDER: " + account_id + '-' + user_id }
      });
      var { data: user_response } = await axios.get("/api/affliate/users/" + user_id, {
        params: { requestName: "GET USER PROFILE " }
      })
      const { data } = response.data;
      localStorage.setItem('userdetails', JSON.stringify(user_response.data));
      setUserProfile(user_response)
      return data;
    } catch (error) {
      return null;
    }
  }

  return {
    addPayoutProvider,
    updatePayoutProvider,
    deletePayoutProvider,
  }


}


export default usePayout;