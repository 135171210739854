import axios from "axios";
import moment from 'moment';
import { createContext, useContext, useState } from "react";
// axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
// axios.defaults.headers.common["x-authkey"] = process.env.APP_AUTH_KEY;

const SalesContext = createContext(undefined);
export function SalesContextProvider({ children }) {
    const [salesList, setSalesList] = useState([])
    const [graphsalesList, setgraphSalesList] = useState([])

    function salesDetails(params) {
        axios.get("/api/affliate/sales", {
            params: {
                ...params,
                requestName: 'GET SALES DETAILS',
            },
        }).then((res) => {
            let resp = changesaleListStructure(res.data.data);
            setSalesList(resp);
            return resp;
        }).catch(err => {
            console.error(err);
        })
    }
    function salesgraphDetails(params) {
        axios.get("/api/affliate/sales", {
            params: {
                ...params,
                requestName: 'GET SALES GRAPH DETAILS',
            }
        }).then((res) => {
            let resp = changeGraphStructure(res.data.data, params.start_date, params.end_date);
            setgraphSalesList(resp as any);
            return resp;
        }).catch(err => {
            console.error(err);
        })
    }
    function changeGraphStructure(data, startDate, endDate) {
        startDate = moment(startDate);
        endDate = moment(endDate);
        const totalsale = data.length;
        const totalDays = moment.duration(endDate.diff(startDate)).asDays() + 1;

        var successfullsale = data.filter((d) => {
            if (d.status === 1) {
                return d;
            }
        });
        var chargeback = data.filter((d) => {
            if (d.status === 2) {
                return d;
            }
        });
        const saledata = totalpercentage(totalDays, startDate, successfullsale, totalsale);
        const chargebackdata = totalpercentage(totalDays, startDate, chargeback, totalsale);
        // console.log(totalDays);

        let successfullsalepercentage = ((successfullsale.length / totalsale) * 100) | 0;
        let chargebackpercentage = ((chargeback.length / totalsale) * 100) | 0;
        return { 'saleGraphData': saledata, 'chargebackGraphData': chargebackdata, 'totalsales': successfullsalepercentage, 'totalchargeback': chargebackpercentage }
    }
    function totalpercentage(totalDays, startDate, data, totalsale) {
        let addDataByDays = {};
        addDataByDays['data'] = [];
        addDataByDays['labels'] = [];
        var new_date = startDate;

        for (let i = 0; i < totalDays;) {
            if (i > 0) {
                new_date = moment(startDate, "YYYY-MM-DD").add(i, "days");
            }
            let totalperc = 0;
            for (let j = 0; j < data.length;) {
                var sale_date = moment(moment(data[j].created_at).format('YYYY-MM-DD'), 'YYYY-MM-DD');
                // console.log(sale_date.diff(moment(new_date.format("YYYY-MM-DD"))));
                // console.log();

                if (sale_date.diff(moment(new_date.format("YYYY-MM-DD"))) === 0) {
                    totalperc++;

                } else {
                    totalperc = totalperc + 0;
                }
                j++;
            }

            if (i === 0) {
                addDataByDays['labels'].push(startDate.format('YYYY-MM-DD'));
                addDataByDays['data'].push((((totalperc / totalsale) * 100) | 0));
            } else {
                // addDataByDays['chargebacks']={'date':new_date.format('YYYY-MM-DD'),'percentage':((totalperc/totalsale)*100) |0}
                addDataByDays['labels'].push(new_date.format('YYYY-MM-DD'));
                addDataByDays['data'].push((((totalperc / totalsale) * 100) | 0));

            }
            i++;
        }
        return addDataByDays;
    }
    function changesaleListStructure(data) {
        let tabledata = [];
        data.forEach((sale) => {
            tabledata.push({ 'orderNo': sale.id, 'requestId': sale.user_id, 'price': sale.price, 'refundType': sale.sale_note, 'status': 'Approved' })
        });
        return tabledata;
    }
    return (
        <SalesContext.Provider
            value={{ salesList, salesDetails, graphsalesList, salesgraphDetails }}
        >
            {children}
        </SalesContext.Provider>
    );
}

export function useSalesContext() {
    return useContext(SalesContext);
}