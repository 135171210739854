export const validResponse = (data) => data.status === "success";

export const requiredData = (data, key) =>
  data?.error?.[key]?.rule ? data?.error?.[key]?.rule === "required" : false;

export const dataExist = (data) => data || data === 0 || data === "0";

export const formatPoint = (points, group_by) => {
  const result = [];

  const counts = {};

  for (const _point of points) {
    const { point, count } = _point;
    counts[point] = count;
  }

  switch (group_by) {
    case "month":
      for (let point = 1; point <= 12; point++) {
        const count = counts[point] || 0;
        result.push({ point, count });
      }
      break;

    case "week_day":
      for (let point = 1; point <= 7; point++) {
        const count = counts[point] || 0;
        result.push({ point, count });
      }
      break;

    default:
      break;
  }

  return result;
};

export const getProgressiveChange = (number_array) => {
  if (Array.isArray(number_array)) {
    let valid = [];
    number_array.forEach((item) => (item ? valid.push(item) : ""));

    while (valid.length > 2) {
      const avg = [];

      valid.forEach((num, index) => {
        if (index < valid.length - 1) {
          avg.push((num + valid[index + 1]) / 2);
        }
      });

      valid = avg;
    }

    if (valid.length == 2) {
      const change = ((valid[1] - valid[0]) / valid[0]) * 100;

      if (change) {
        return parseInt(change);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  return "-";
};

export const percentageChange = (a, b) => {
  if (!b) {
    return 0;
  }

  return ((a / b) * 100).toFixed();
};

export const campaignStatusMap = {
  0: { slug: "pending", text: "Pending" },
  1: { slug: "active", text: "Active" },
  2: { slug: "paused", text: "Paused (visible)" },
  3: { slug: "stopped", text: "Stopped (invisible)" },
};

export const separateCharacters = (inputString) => {
  if (inputString.length !== 24) {
    return inputString;
  }

  return inputString.match(/.{1,6}/g).join("-");
};

export const formatPrice = (value) =>
  parseFloat(value).toFixed(2).toLocaleString();

export const transformMonthEarning = (data) => {
  const result = { labels: [], values: [] };

  const minDate = new Date(
    Math.min.apply(
      null,
      data.map((item) =>
        new Date(item.point.year, item.point.month - 1, 1).getTime()
      )
    )
  );
  const maxDate = new Date(
    Math.max.apply(
      null,
      data.map((item) =>
        new Date(item.point.year, item.point.month - 1, 1).getTime()
      )
    )
  );

  const dateRange = [];
  let currentDate = minDate;
  while (currentDate <= maxDate) {
    dateRange.push(new Date(currentDate));
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  const monthAbbr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  for (const date of dateRange) {
    const label = `${monthAbbr[date.getMonth()]}, ${date.getFullYear()}`;
    const count =
      data.find(
        (item) =>
          item.point.year === date.getFullYear() &&
          item.point.month === date.getMonth() + 1
      )?.count || 0;

    result.labels.push(label);
    result.values.push(count);
  }

  return result;
};

export const transformYearEarning = (data) => {
  const result = { labels: [], values: [] };

  const minYear = Math.min(...data.map((item) => item.point.year));
  const maxYear = Math.max(...data.map((item) => item.point.year));

  const yearRange = Array.from(
    { length: maxYear - minYear + 1 },
    (_, index) => minYear + index
  );

  for (const year of yearRange) {
    result.labels.push(year);
    result.values.push(
      data.find((item) => item.point.year === year)?.count || 0
    );
  }

  return result;
};

export const transformDayEarning = (data) => {
  const result = { labels: [], values: [] };

  const minDate = new Date(
    Math.min.apply(
      null,
      data.map((item) =>
        new Date(
          item.point.year,
          item.point.month - 1,
          item.point.dayOfMonth
        ).getTime()
      )
    )
  );
  const maxDate = new Date(
    Math.max.apply(
      null,
      data.map((item) =>
        new Date(
          item.point.year,
          item.point.month - 1,
          item.point.dayOfMonth
        ).getTime()
      )
    )
  );

  const currentDate = new Date(minDate);

  while (currentDate <= maxDate) {
    const label = `${currentDate.getDate()} ${getMonthAbbr(
      currentDate.getMonth()
    )}, ${currentDate.getFullYear().toString().slice(-2)}`;

    const count =
      data.find(
        (item) =>
          item.point.year === currentDate.getFullYear() &&
          item.point.month === currentDate.getMonth() + 1 &&
          item.point.dayOfMonth === currentDate.getDate()
      )?.count || 0;

    result.labels.push(label);
    result.values.push(count);

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return result;
};

const getDayOfWeek = (dayIndex) => {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

  return daysOfWeek[dayIndex];
};

const getMonthAbbr = (monthIndex) => {
  const monthAbbr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return monthAbbr[monthIndex];
};

export const mapLanguage = {
  en: "English",
  fr: "French",
};

export const getReferralLink = ({ ref_code, promotion_url }) => {
  return `${promotion_url}?ref=${ref_code}`;
};

export const getHTML = ({
  ref_code,
  type,
  url,
  asset,
  size,
  promotion_url,
}) => {
  switch (type) {
    case "social":
      return `${url}/${ref_code}/${type}/${asset}`;

    case "script":
      return `<script src="${`${url}/${ref_code}/${type}/${asset}`}"
        data-width="${size?.split("x")?.[0]}"
        data-height="${size?.split("x")?.[1]}"
        data-url="${promotion_url}"
        data-ref="${ref_code}"
      ></script>
      <div id="widget-container"></div>`;

    default:
      break;
  }
};

export const getHTMLPreview = ({ asset }, url) => {
  const file_name = asset?.split(".")?.[0];

  return `${url}/thumbnail/${file_name}.png`;
};
